import React from 'react'
import { Helmet } from 'react-helmet'

const title = 'Terms of Service | Cardeon'
const url = 'https://cardeon.io/terms'
const desc = "Welcome to Cardeon's Terms of Service."

function Terms() {
    return (
        <div className="terms flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12 pt-5">
                        <h1 className="mt-4">
                            Welcome to Cardeon's Terms of Service
                        </h1>
                        <p>
                            Please read these Terms of Service ("Terms", "Terms
                            of Service”) carefully before using the Cardeon
                            mobile application (the "Service" or the "Cardeon
                            App") operated by Eva, LLC ("us", "we", "our", the
                            "company”, “app”, “site” or "Cardeon").
                        </p>
                        <p>
                            These Terms of Service govern your use of Cardeon,
                            our website located at http://cardeon.io/, and any
                            related services provided by Eva LLC.
                        </p>
                        <p>
                            This policy is effective as of 25 April 2022. Last
                            updated: 23 August 2023
                        </p>
                        <p>
                            When you create an Cardeon account or use Cardeon,
                            you agree to abide by these Terms of Service and to
                            comply with all applicable laws and regulations. If
                            you do not agree with these Terms of Service, you
                            are prohibited from further using the app, accessing
                            our website, or using any other services provided by
                            Eva LLC.
                        </p>
                        <p>
                            If you access or download Cardeon from (1) the Apple
                            App Store, you agree to any Usage Rules set forth in
                            the App Store Terms of Service; and/or (2) the
                            Google Play Store, you agree to the Android, Google
                            Inc. Terms and Conditions including the Google Apps
                            Terms of Service.
                        </p>
                        <p>
                            We, Eva LLC, reserve the right to review and amend
                            any of these Terms of Service at our sole
                            discretion. Upon doing so, we will update this page
                            and notify you through the app and/or the email
                            address you provided when you created your account.
                            Any changes to these Terms of Service will take
                            effect immediately from the date of publication.
                        </p>
                        <strong className="pt-5">Communications</strong>
                        <p>
                            By creating an Account on our service, you agree to
                            subscribe to newsletters, marketing or promotional
                            materials and other information we may send.
                            However, you may opt out of receiving any, or all,
                            of these communications from us by following the
                            unsubscribe link or instructions provided in any
                            email we send.
                        </p>
                        <strong className="pt-5">Limitations of Use</strong>
                        <p>
                            By using Cardeon and our website, you warrant on
                            behalf of yourself, any entity who you represent who
                            has entered into these Terms of Service, and your
                            users that you will not:
                        </p>
                        <p>
                            <span className="mx-2">1.</span>Modify, copy,
                            prepare derivative works of, decompile, or reverse
                            engineer Cardeon or any materials and software
                            contained within Cardeon or on our website;
                        </p>
                        <p>
                            <span className="mx-2">2.</span>Remove any copyright
                            or other proprietary notations from Cardeon or any
                            materials and software contained within Cardeon or
                            on our website;
                        </p>
                        <p>
                            <span className="mx-2">3.</span>Transfer Cardeon or
                            any of its associated materials to another person or
                            “mirror” the materials on any other server;
                        </p>
                        <p>
                            <span className="mx-2">4.</span>Knowingly or
                            negligently use Cardeon or any of its associated
                            services in a way that abuses or disrupts our
                            networks or any other service Eva LLC provides;
                        </p>
                        <p>
                            <span className="mx-2">5.</span>Use Cardeon or its
                            associated services to transmit or publish any
                            harassing, indecent, obscene, fraudulent, or
                            unlawful material;
                        </p>
                        <p>
                            <span className="mx-2">6.</span>Use Cardeon or its
                            associated services in violation of any applicable
                            laws or regulations;
                        </p>
                        <p>
                            <span className="mx-2">7.</span>Use Cardeon to send
                            unauthorized advertising or spam;
                        </p>
                        <p>
                            <span className="mx-2">8.</span>Harvest, collect, or
                            gather user data without the user's consent; or
                        </p>
                        <p className="mb-5">
                            <span className="mx-2">9.</span>Use Cardeon or its
                            associated services in such a way that may infringe
                            the privacy, intellectual property rights, or other
                            rights of third parties.
                        </p>
                        <span className="fw-500 gray-700 mt-3">
                            Messaging Rules
                        </span>
                        <p>
                            Cardeon allows you to connect and message with other
                            users through a messaging portal. By registering for
                            an account and accessing the messaging portal, you
                            agree to the following rules:
                        </p>
                        <p>
                            <span className="mx-2">·</span>Do not share your
                            login password or username.
                        </p>
                        <p>
                            <span className="mx-2">·</span>Do not reprint,
                            republish, repost or otherwise distribute or
                            transmit content or images presented on this site.
                            Unauthorized use of or copying of our content,
                            trademarks and other proprietary materials can
                            subject you to civil or even criminal liability.
                        </p>
                        <p>
                            <span className="mx-2">·</span>No advertising,
                            soliciting of members, trading of goods or services,
                            or other commercial use is allowed in our messaging
                            portal. No bulk e-mail, junk mail, spam, or chain
                            letters are permitted in the messaging portal.
                            Soliciting of members is strictly prohibited and
                            will result in the immediate termination of your
                            account.
                        </p>
                        <p>
                            <span className="mx-2">·</span>Do not share personal
                            information, including email address, phone number,
                            physical address or other contact information in the
                            messaging portal.
                        </p>
                        <p className="mb-5">
                            <span className="mx-2">·</span>Treat other members
                            with courtesy and respect when interacting in the
                            messaging portal. Do not use vulgarity, obscenity,
                            profanity, ethnic slurs, hate speech, or sexually
                            explicit language in the messaging portal. Do not
                            harass, abuse or threaten other members.
                        </p>
                        <span className="fw-500 gray-700 mt-3">Accounts</span>
                        <p>
                            When you create an account with the Cardeon App, you
                            guarantee that you are above the age of 13, and that
                            the information you provide us is accurate,
                            complete, and up to date at all times. Inaccurate,
                            incomplete, or obsolete information may result in
                            the immediate termination of your account on the
                            Service.
                        </p>
                        <p>
                            You understand that by creating an account with your
                            name, age, gender, and location, your profile is
                            public to other Cardeon members. You and other
                            members can search for members by location, age,
                            gender, and user type. You can restrict others from
                            searching for you by changing your visibility
                            settings under the profile setting. If you make
                            yourself invisible, you will not be able to search
                            for other members, and other members will not be
                            able to search for you. You can still message
                            members you have already had contact with.
                        </p>
                        <span className="fw-500 gray-700 mt-3">
                            You must be at least the age of 13 to use the
                            Service.
                        </span>
                        <p>
                            If you are at least the age of 13, but less than the
                            age of 18, you acknowledge and agree to these terms
                            of use and in addition; you represent and certify
                            that you have obtained permission to use or access
                            the Service from a parent or legal guardian.
                        </p>
                        <p>
                            You are responsible for maintaining the
                            confidentiality of your account and password,
                            including but not limited to the restriction of
                            access to your computer and/or account. You agree to
                            accept responsibility for any and all activities or
                            actions that occur under your account and/or
                            password, whether your password is with our Service
                            or a third-party service. You must notify us
                            immediately upon becoming aware of any breach of
                            security or unauthorized use of your account.
                        </p>
                        <p>
                            You may not use as a username the name of another
                            person or entity or that is not lawfully available
                            for use, a name or trademark that is subject to any
                            rights of another person or entity other than you,
                            without appropriate authorization. You may not use
                            as a username any name that is offensive, vulgar or
                            obscene.
                        </p>
                        <p>
                            If you learn that your child has provided us with
                            personal information without your consent or if you
                            learn that your child has provided us with personal
                            information and is under the age of 13, please
                            contact us immediately.
                        </p>
                        <strong className="pt-5">Intellectual Property</strong>
                        <p>
                            The intellectual property in the materials in
                            Cardeon and on our website are owned by or licensed
                            to Eva LLC. You may download Cardeon, to view, use,
                            and display the application on your mobile device
                            for your personal use only.
                        </p>
                        <p>
                            This constitutes the grant of a license, not a
                            transfer of title. This license shall automatically
                            terminate if you violate any of these restrictions
                            or these Terms of Service, and may be terminated by
                            Eva LLC at any time.
                        </p>
                        <strong className="pt-5">User-Generated Content</strong>
                        <p>
                            You retain your intellectual property ownership
                            rights over content you submit to us for publication
                            within Cardeon and/or on its corresponding website.
                            We will never claim ownership of your content, but
                            we do require a license from you in order to use it.
                        </p>
                        <p>
                            When you use Cardeon or its associated services to
                            post, upload, share, or otherwise transmit content
                            covered by intellectual property rights, you grant
                            to us a non-exclusive, royalty-free, transferable,
                            sub-licensable, worldwide license to use,
                            distribute, modify, run, copy, publicly display,
                            translate, or otherwise create derivative works of
                            your content in a manner that is consistent with
                            your privacy preferences and our Privacy Policy.
                        </p>
                        <p>
                            The license you grant us can be terminated at any
                            time by deleting your content or account. However,
                            to the extent that we (or our partners) have used
                            your content in connection with commercial or
                            sponsored content, the license will continue until
                            the relevant commercial or post has been
                            discontinued by us. You give us permission to use
                            your username and other identifying information
                            associated with your account in a manner that is
                            consistent with your privacy preferences, and our
                            Privacy Policy.
                        </p>
                        <strong className="pt-5">Automatic Updates</strong>
                        <p>
                            You give us permission to download and install
                            updates to Cardeon on your device in accordance with
                            your privacy preferences. This permission can be
                            revoked at any time by deleting Cardeon from your
                            device.
                        </p>
                        <strong className="pt-5">Indemnification</strong>
                        <p>
                            You agree to defend, indemnify and hold harmless Eva
                            LLC and its licensee and licensors, and their
                            employees, contractors, agents, officers and
                            directors, from and against any and all claims,
                            damages, obligations, losses, liabilities, costs or
                            debt, and expenses (including but not limited to
                            attorney's fees), resulting from or arising out of
                            a) your use and access of the Service, by you or any
                            person using your account and password; b) a breach
                            of these Terms, or c) Content posted on the Service.
                        </p>
                        <strong className="pt-5">Liability</strong>
                        <p>
                            Your use of the Service is at your sole risk.
                            Cardeon and the materials in Cardeon and on our
                            website are provided on an 'as is' and 'as
                            available' basis. To the extent permitted by law,
                            Eva LLC makes no warranties, expressed or implied,
                            and hereby disclaims and negates all other
                            warranties including, without limitation, implied
                            warranties or conditions of merchantability, fitness
                            for a particular purpose, or non-infringement of
                            intellectual property, or other violation of rights.
                        </p>
                        <p>
                            In no event shall Eva LLC or its suppliers be liable
                            for any consequential loss suffered or incurred by
                            you or any third party arising from the use or
                            inability to use Cardeon, our website, or any other
                            services provided by Eva LLC or the materials in
                            Cardeon, even if Eva LLC or an authorized
                            representative has been notified, orally or in
                            writing, of the possibility of such damage.
                        </p>
                        <p>
                            In the context of this agreement, “consequential
                            loss” includes any consequential loss, indirect
                            loss, real or anticipated loss of profit, loss of
                            benefit, loss of revenue, loss of business, loss of
                            goodwill, loss of opportunity, loss of savings, loss
                            of reputation, loss of use and/or loss or corruption
                            of data, whether under statute, contract, equity,
                            tort (including negligence), indemnity, or
                            otherwise.
                        </p>
                        <p>
                            Because some jurisdictions do not allow limitations
                            on implied warranties, or limitations of liability
                            for consequential or incidental damages, these
                            limitations may not apply to you.
                        </p>
                        <strong className="pt-5">Accuracy of Materials</strong>
                        <p>
                            The materials appearing in Cardeon or on our website
                            are not comprehensive and are for general
                            information purposes only. To the extent permitted
                            by law, Eva LLC does not warrant or make any
                            representations concerning the accuracy, likely
                            results, or reliability of the use of the materials
                            in Cardeon or on our website, or otherwise relating
                            to such materials or on any resources linked to
                            Cardeon and our website.
                        </p>
                        <strong className="pt-5">Links</strong>
                        <p>
                            Eva LLC has not reviewed all of the sites linked to
                            Cardeon or on its corresponding website and is not
                            responsible for the contents of any such linked
                            site. The inclusion of any link does not imply
                            endorsement, approval, or control by Eva LLC of the
                            site. Use of any such linked website is at your own
                            risk and we strongly advise you make your own
                            investigations with respect to the suitability of
                            those sites.
                        </p>
                        <strong className="pt-5">Notice regarding Apple</strong>
                        <p>
                            To the extent that you are using or accessing
                            Cardeon on an iOS device, you acknowledge and agree
                            to the terms of this clause. You acknowledge that
                            these Terms of Service are between you and Eva LLC
                            only, not with Apple Inc. (Apple), and Apple is not
                            responsible for Cardeon and any materials available
                            in Cardeon.
                        </p>
                        <p>
                            Apple has no obligation to furnish you with any
                            maintenance and support services with respect to
                            Cardeon.
                        </p>
                        <p>
                            If Cardeon fails to conform to any applicable
                            warranty, you may notify Apple and Apple will refund
                            the purchase price of the mobile application to you.
                            To the maximum extent permitted by applicable law,
                            Apple will have no other warranty obligation
                            whatsoever with respect to Cardeon and any other
                            claims, losses, liabilities, damages, costs, or
                            expenses attributable to any failure to conform to
                            any warranty will be our responsibility.
                        </p>
                        <p>
                            Apple is not responsible for addressing any claims
                            by you or any third party relating to Cardeon or
                            your use of Cardeon, including but not limited to
                            (1) product liability claims; (2) any claim that our
                            mobile application fails to conform to any
                            applicable legal or regulatory requirement; and (3)
                            claims arising under consumer protection or similar
                            legislation.
                        </p>
                        <p>
                            Apple is not responsible for the investigation,
                            defense, settlement, and discharge of any
                            third-party claim that our mobile application
                            infringes that third party’s intellectual property
                            rights.
                        </p>
                        <p>
                            You agree to comply with any applicable third-party
                            terms when using Cardeon, including any Usage Rules
                            set forth in the Apple App Store Agreement of
                            Service.
                        </p>
                        <p>
                            Apple and Apple's subsidiaries are third-party
                            beneficiaries of these Terms of Service, and upon
                            your acceptance of these Terms of Service, Apple
                            will have the right (and will be deemed to have
                            accepted the right) to enforce these Terms of
                            Service against you as a third-party beneficiary of
                            these Terms of Service.
                        </p>
                        <p>
                            You hereby represent and warrant that (1) you are
                            not located in a country that is subject to a U.S.
                            Government embargo, or that has been designated by
                            the U.S. Government as a "terrorist supporting"
                            country; and (2) you are not listed on any U.S.
                            Government list of prohibited or restricted parties.
                        </p>
                        <strong className="pt-5">Right to Terminate</strong>
                        <p>
                            We may terminate or suspend your account and bar
                            access to the Service immediately, without prior
                            notice or liability, under our sole discretion, for
                            any reason whatsoever and without limitation,
                            including but not limited to a breach of the Terms.
                        </p>
                        <p>
                            If you wish to terminate your account, you may
                            simply discontinue using the Service or email
                            support@cardeon.io.
                        </p>
                        <p>
                            All provisions of the Terms which by their nature
                            should survive termination shall survive
                            termination, including, without limitation,
                            ownership provisions, warranty disclaimers,
                            indemnity and limitations of liability.
                        </p>
                        <strong className="pt-5">Severance</strong>
                        <p>
                            Any term of these Terms of Service which is wholly
                            or partially void or unenforceable is severed to the
                            extent that it is void or unenforceable. The
                            validity of the remainder of these Terms of Service
                            is not affected.
                        </p>
                        <strong className="pt-5">Governing Law</strong>
                        <p>
                            These Terms of Service are governed by and construed
                            in accordance with the laws of North Carolina. You
                            irrevocably submit to the exclusive jurisdiction of
                            the courts in that State or location.
                        </p>
                        <strong className="pt-5">Changes</strong>
                        <p>
                            We reserve the right, at our sole discretion, to
                            modify or replace these Terms at any time. If a
                            revision is material we will provide at least 30
                            days notice prior to any new terms taking effect.
                            What constitutes a material change will be
                            determined at our sole discretion.
                        </p>
                        <p>
                            By continuing to access or use our Service after any
                            revisions become effective, you agree to be bound by
                            the revised terms. If you do not agree to the new
                            terms, you are no longer authorized to use the
                            Service.
                        </p>
                        <strong className="pt-5">Contact Us</strong>
                        <p className="my-0 mb-2">
                            For any questions or concerns regarding this terms
                            of service, you may contact us using the following
                            details:
                        </p>
                        <a
                            href="mailto: abc@example.com"
                            title="Email Support"
                            className="brand-secondary fw-500">
                            support@cardeon.io
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Terms
