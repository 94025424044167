// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth'
// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//dev
const firebaseConfig = {
    apiKey: 'AIzaSyB0AAlZ2fb8LSE9Sf4BI1SEutub5auTcfo',
    authDomain: 'cardeon-dev.firebaseapp.com',
    projectId: 'cardeon-dev',
    storageBucket: 'cardeon-dev.appspot.com',
    messagingSenderId: '309589536959',
    appId: '1:309589536959:web:5b1073cab9faf468a2d60a',
    measurementId: 'G-P6DJBG1BR1'
}

//prod
// const firebaseConfig = {
//     apiKey: 'AIzaSyDPNkLLg8HxxbzxLIoqvQdWWVRagjAk9fY',
//     authDomain: 'cardeon-api.firebaseapp.com',
//     projectId: 'cardeon-api',
//     storageBucket: 'cardeon-api.appspot.com',
//     messagingSenderId: '156476644432',
//     appId: '1:156476644432:web:5049f46b6c2b5f05328555',
//     measurementId: 'G-L3728V6385'
// }

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app)

export { auth }
