import React, { useEffect, useState, useRef } from 'react'
import {
    useNavigate,
    useLocation,
    Link,
    useMatch,
    useResolvedPath
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import authService from '../../services/auth.service'
import { resetUser } from '../../config/slice/user.slice'
import { userSubs } from '../../config/slice/subs.slice'
import { selectedPlan } from '../../config/slice/selectedPlan.slice'
import { modalState } from '../../config/slice/component.slice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Navigation() {
    const location = useLocation()
    const { pathname } = location
    const rootPathName = `/${pathname.split('/')[1]}`
    const privatePaths = {
        '/account': '/account',
        '/content': '/content',
        '/my-team': '/my-team',
        '/profile': '/profile',
        '/search': '/search',
        '/admin': '/admin',
    }
    const authPaths = {
        '/signin': '/signin',
        '/signup': '/signup',
        '/disclaimer': '/disclaimer',
        '/pre-assessment': '/pre-assessment'
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const user = useSelector(state => state.user.value)
    const { loading: userLoading, user: user } = useSelector(
        state => state.user
    )
    const subs = useSelector(state => state.subs.value)
    let resolved = useResolvedPath()
    let match = useMatch({ path: resolved.pathname, end: true })
    let selected = match.pathname

    const [isActive, setActive] = useState(false)
    const [accountPath, setAccountPath] = useState(false)
    const wrapperRef = useRef(null)
    const closeOpenMenus = e => {
        if (
            wrapperRef.current &&
            isActive &&
            !wrapperRef.current.contains(e.target)
        ) {
            setActive(false)
        }
    }
    document.addEventListener('mousedown', closeOpenMenus)

    useEffect(() => {
        async function init() {
            if (user.role === 'Admin') {
                setAccountPath('/admin')
            } else if (user.intent === 'Provider' && subs != null) {
                setAccountPath('/content')
            } else {
                setAccountPath('/account')
            }
        }
        init()
    }, [user])

    const toggleMenu = () => {
        setActive(!isActive)
    }

    const closeMenu = () => {
        setActive(false)
    }

    async function startTrial(e) {
        e.preventDefault()
        if (subs) {
            dispatch(modalState(true))
        } else if (user?.id) {
            dispatch(selectedPlan('trial'))
            navigate('/account')
        } else {
            dispatch(selectedPlan('trial'))
            navigate('/signup')
        }
    }

    const onLogout = async () => {
        setActive(false)
        try {
            authService.logout().then(() => {
                navigate('/signin')
                dispatch(resetUser())
                dispatch(selectedPlan(''))
                dispatch(userSubs(''))
            })
        } catch (e) {
            toast.error('Unknown Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            return
        }
    }

    return (
        <nav>
            {authPaths[rootPathName] && (
                <div className="flex-r container py-4">
                <div ref={wrapperRef} className="flex-r flex-grow-1 row">
                    <div className="flex-r col-12 align-items-center">
                        <div className="flex-r flex-1 justify-content-center">
                                <Link
                                    type="button"
                                    to="/"
                                    onClick={closeMenu}
                                    title="Home">
                                    <div className="flex-r align-items-center">
                                        <div className="logo"></div>
                                        <h2 className="gray-900 font-20 my-0 ml-1">
                                            Card
                                            <span className="fw-300">eon</span>
                                        </h2>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {privatePaths[rootPathName] && (
                <div className="flex-r task-bar">
                    <div className="flex-r flex-grow-1">
                        <span className="font-20 fw-500 white">
                            {[rootPathName]
                                .toString()
                                .slice(1)
                                .charAt(0)
                                .toUpperCase() +
                                [rootPathName].toString().slice(2)}
                        </span>
                    </div>
                    <div className="flex-r align-items-center">
                        <a
                            // className={`${user.role !== 'Admin' ? 'mr-1' : ''}`}
                            onClick={onLogout}
                            style={{ cursor: 'pointer' }}
                            title="Sign Out">
                            <div className="nav-square text-c">
                                <i className="fa-solid fa-right-from-bracket"></i>
                            </div>
                        </a>
                        {/* {user.role !== 'Admin' &&
                            <Link
                                className="ml-1"
                                onClick={e => {
                                    console.log('To My Profile')
                                }}
                                to={'/account'}
                                type="button"
                                title="My Profile">
                                <div className="nav-square text-c">
                                    <i className="fa-solid fa-user"></i>
                                </div>
                            </Link>
                        } */}
                        {/* <div className="flex-c img-user" style={{backgroundColor: user.gravatar}}>
                            {user.profilemedia ? (
                                <img src={user && user.profilemedia} alt={user && user.firstname[0] + user && user.lastname[0]} title="Profile Image" />
                            ) : (
                                <div className="flex-r align-items-center justify-content-center flex-grow-1 white fw-500">
                                    <span>{user && user.firstname[0]}</span>
                                    <span>{user && user.lastname[0]}</span>
                                </div>
                            )}
                        </div> */}
                    </div>
                </div>
            )}
            {!(privatePaths[rootPathName] || authPaths[rootPathName]) && (
                <div className="flex-r container py-4">
                    <div ref={wrapperRef} className="flex-r flex-grow-1 row">
                        <div className="flex-r col-12 align-items-center">
                            <div className="flex-r flex-1">
                                <Link
                                    type="button"
                                    className="logo-link"
                                    to="/"
                                    onClick={closeMenu}
                                    title="Home">
                                    <div className="flex-r align-items-center">
                                        <div className="logo"></div>
                                        <h2 className="gray-900 font-20 my-0 ml-1">
                                            Card
                                            <span className="fw-300">eon</span>
                                        </h2>
                                    </div>
                                </Link>
                                <div
                                    className={`flex-c flex-lg-r align-items-lg-center menu-control ${
                                        isActive ? 'open' : null
                                    }`}>
                                    <span
                                        className={`nav-item ${
                                            selected === '/about' ? 'active' : ''
                                        }`}>
                                        <Link
                                            className="nav-link"
                                            to="/about"
                                            onClick={closeMenu}
                                            title="About">
                                            About
                                        </Link>
                                    </span>
                                    <span
                                        className={`nav-item ${
                                            selected === '/team' ? 'active' : ''
                                        }`}>
                                        <Link
                                            className="nav-link"
                                            to="/team"
                                            onClick={closeMenu}
                                            title="Team">
                                            Team
                                        </Link>
                                    </span>
                                    <span
                                        className={`nav-item ${
                                            selected === '/pricing' ? 'active' : ''
                                        }`}>
                                        <Link
                                            className="nav-link"
                                            to="/pricing"
                                            onClick={closeMenu}
                                            title="Pricing">
                                            Pricing
                                        </Link>
                                    </span>
                                    <span
                                        className={`nav-item ${
                                            selected === '/contact' ? 'active' : ''
                                        }`}>
                                        <Link
                                            className="nav-link"
                                            to="/contact"
                                            title="Contact Us">
                                            Contact Us
                                        </Link>
                                    </span>
                                    <span className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to="https://calendly.com/cardeonapp"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Product Demo">
                                            Demo
                                        </Link>
                                    </span>
                                    {user.id && (
                                        <span
                                            className={`nav-item ${
                                                selected === '/account'
                                                    ? 'active'
                                                    : ''
                                            }`}>
                                            <Link
                                                className="nav-link mx-lg-3 mb-3 mb-lg-0"
                                                to={accountPath}
                                                onClick={closeMenu}
                                                title="My Account">
                                                My Account
                                            </Link>
                                        </span>
                                    )}
                                    <span className="divider menu-divider mt-2" />
                                    {user.id ? (
                                        <Link
                                            className="display-hide display-lg-show btn btn-nav"
                                            onClick={onLogout}
                                            to="signup"
                                            title="Sign Out">
                                            <i className="fa-solid fa-circle-left display-lg-hide mr-2"></i>{' '}
                                            Sign Out
                                        </Link>
                                    ) : (
                                        <Link
                                            type="button"
                                            className={`display-hide btn btn-nav mr-0 ${
                                                selected === '/signin'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={closeMenu}
                                            to="/signin"
                                            title="Sign In">
                                            Sign In
                                        </Link>
                                    )}
                                </div>
                            </div>
                            <div className="flex-r display-hide display-lg-flex">
                                <Link
                                    type="button"
                                    className="btn btn-white btn-small mr-md-4"
                                    onClick={closeMenu}
                                    to="/signin"
                                    title="Sign In">
                                    Sign In
                                </Link>
                                <Link
                                    type="button"
                                    className="btn btn-secondary btn-small"
                                    // to="/disclaimer"
                                    to="/maintenance"
                                    title="Get Started Free">
                                    Get Started Free
                                </Link>
                            </div>
                            <Link
                                type="button"
                                className="d-flex d-lg-none ml-4">
                                <i
                                    className={`gray-900 fas ${
                                        isActive ? 'fa-times' : 'fa-bars'
                                    }`}
                                    onClick={toggleMenu}
                                    title="Toggle Menu Visibility"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    )
}

export default Navigation
